import Big from 'big.js'
import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'

import { AppContext } from './AppContext/AppContext'
import FiatNumber from './FiatNumber'

const CollateralAssetInfo = function ({
  asset,
  className = '',
  infinityThreshold = 1e20
}) {
  const { t } = useTranslation()
  const {
    state: {
      fees: { liquidationPenalty }
    }
  } = useContext(AppContext)

  const availableSupply = Big(asset.availableSupply)

  const depositLimit = availableSupply.gt(infinityThreshold)
    ? '∞'
    : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 3,
        notation: 'compact'
      }).format(availableSupply.toNumber())
  return (
    <div className={`mt-4 space-y-2 text-sm ${className}`}>
      <p className="flex justify-between capitalize">
        <span className="text-gray-500">
          {t('asset-tooltip.deposit-limit')}
        </span>
        <span>{depositLimit}</span>
      </p>
      <p className="flex justify-between capitalize">
        <span className="text-gray-500">{t('asset-tooltip.usd-price')}</span>
        <FiatNumber value={asset?.usdPrice || 0} />
      </p>
      {asset.collateralFactor && (
        <p className="flex justify-between capitalize">
          <span className="text-gray-500">{t('cr')}</span>
          <span>{asset.collateralFactor}%</span>
        </p>
      )}
      <p className="flex justify-between capitalize">
        <span className="text-gray-500">
          {t('asset-tooltip.liquidation-penalty')}
        </span>
        {liquidationPenalty || 0}%
      </p>
      {asset.apy && (
        <p className="flex justify-between uppercase">
          <span className="text-gray-500">{t('apy')}</span>
          <span>{asset.apy}%</span>
        </p>
      )}
    </div>
  )
}

export default CollateralAssetInfo
