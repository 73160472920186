import Big from 'big.js'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useContext, useEffect } from 'react'

import useSortSynthetics from '../hooks/useSortSynthetics2'
import { sortByNumberProp } from '../utils/sort'

import { AppContext } from './AppContext/AppContext'
import AssetList from './AssetList'
import SyntheticAssetInfo from './SyntheticAssetInfo'

function SyntheticAssetList() {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    state: {
      assets: { synthetics },
      wallet: { balancesLoaded }
    }
  } = useContext(AppContext)

  const { buildSortingUrl, currentSort, order, sortFn, SortingArrow } =
    useSortSynthetics()

  const userSyntheticTokens = [...synthetics].sort(
    sortByNumberProp(['generated'])
  )

  const noSyntheticBalance = Big(
    userSyntheticTokens.reduce(
      (accumulator, { balance = 0 }) => Big(accumulator).plus(balance),
      0
    )
  ).eq(0)

  const noDebtBalance = Big(
    userSyntheticTokens.reduce(
      (accumulator, { generated = 0 }) => Big(accumulator).plus(generated),
      0
    )
  ).eq(0)

  const orderedUserSyntheticTokens =
    order === 'desc'
      ? [...userSyntheticTokens].sort(sortFn).reverse()
      : [...userSyntheticTokens].sort(sortFn)

  const getSortingCriteria = useCallback(
    function () {
      if (!balancesLoaded || router.query['sort-synthetics']) {
        return null
      }

      if (!noSyntheticBalance) {
        return ['synthetic', 'desc']
      }

      if (!noDebtBalance) {
        return ['debt', 'desc']
      }
      return undefined
    },
    [noDebtBalance, noSyntheticBalance, router.query, balancesLoaded]
  )

  useEffect(
    function () {
      if (
        !balancesLoaded &&
        (currentSort === 'debt' || currentSort === 'synthetic')
      ) {
        const { shortName } = router.query
        router.push(`/${shortName}`, undefined, {
          shallow: true
        })
      } else {
        const sortCriteria = getSortingCriteria()
        if (sortCriteria) {
          const href = buildSortingUrl(...sortCriteria)
          router.push(href, undefined, { shallow: true })
        }
      }
    },
    [buildSortingUrl, currentSort, getSortingCriteria, router, balancesLoaded]
  )

  useEffect(
    function () {
      if (balancesLoaded) {
        if (!router.query['sort-synthetics']) {
          if (noDebtBalance) {
            if (noSyntheticBalance) {
              const href = buildSortingUrl('asset', 'asc')
              router.push(href, undefined, { shallow: true })
            } else {
              const href = buildSortingUrl('synthetic', 'desc')
              router.push(href, undefined, { shallow: true })
            }
          } else {
            const href = buildSortingUrl('debt', 'desc')
            router.push(href, undefined, { shallow: true })
          }
        }
      } else if (currentSort === 'synthetic' || currentSort === 'debt') {
        const { shortName } = router.query
        router.push(`/${shortName}`, undefined, {
          shallow: true
        })
      }
    },
    [
      buildSortingUrl,
      currentSort,
      noDebtBalance,
      noSyntheticBalance,
      router,
      balancesLoaded
    ]
  )

  return (
    <AssetList
      SortingArrow={SortingArrow}
      columns={[
        {
          disableSort: noSyntheticBalance,
          key: 'synthetic',
          title: t('wallet')
        },
        {
          disableSort: noDebtBalance,
          key: 'debt',
          title: t('generated')
        }
      ]}
      title={t('synth-assets')}
    >
      {orderedUserSyntheticTokens.map(asset => (
        <AssetList.Item
          InfoComponent={SyntheticAssetInfo}
          asset={asset}
          columns={[
            {
              balance: asset.balance,
              balanceInUsd: asset.balanceInUsd,
              key: 'synthetic',
              symbol: asset.symbol,
              title: t('wallet')
            },
            {
              balance: asset.generated,
              balanceInUsd: asset.generatedInUsd,
              key: 'debt',
              symbol: asset.symbol,
              title: t('generated')
            }
          ]}
          key={asset.address}
          operations={['generate', 'repay']}
        />
      ))}
    </AssetList>
  )
}

export default SyntheticAssetList
