import IconContainer from './icons/IconContainer'

const SortingArrow = ({ className = '', hide = false, order }) => (
  <IconContainer
    className={`ml-2 ${order === 'asc' ? 'rotate-90' : '-rotate-90'} ${
      hide ? 'invisible' : ''
    } ${className}`}
    height="15"
    name="backarrow"
    stroke="#259DA8"
    width="15"
  />
)

export default SortingArrow
