import { sortByNumberProp } from '../utils/sort'

import { useSort } from './useSort'

const useSortCollaterals = (path = '') =>
  useSort({
    defaultSort: 'asset-asc',
    path,
    sortKey: 'sort-collaterals',
    sortTypes: [
      {
        defaultSort: 'asc',
        sortFn: (a, b) =>
          a.symbol.toLowerCase().localeCompare(b.symbol.toLowerCase()),
        type: 'asset'
      },
      {
        defaultSort: 'asc',
        sortFn: sortByNumberProp(['balanceInUsd']),
        type: 'collateral'
      },
      {
        defaultSort: 'asc',
        sortFn: sortByNumberProp(['balanceInUsd', 'depositInUsd']),
        type: 'depositToken'
      }
    ]
  })

export default useSortCollaterals
