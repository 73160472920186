import Big from 'big.js'
import useTranslation from 'next-translate/useTranslation'

const SyntheticAssetTooltip = function ({
  asset,
  className = '',
  infinityThreshold = 1e20
}) {
  const { t } = useTranslation()
  const maxTotalSupply = Big(asset.maxTotalSupply)
  const availableDebtSupply = Big(asset.debtAvailableSupply) || 0
  const availableSupply = Big(asset.availableSupply)
  const availableToGenerate = availableSupply.lt(availableDebtSupply)
    ? availableSupply
    : availableDebtSupply

  const synthCap = maxTotalSupply.gt(infinityThreshold)
    ? '∞'
    : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 3,
        notation: 'compact'
      }).format(maxTotalSupply.toNumber())

  let remainingSynths
  if (availableSupply.gt(infinityThreshold)) {
    remainingSynths = '∞'
  } else if (availableSupply.lte(0)) {
    remainingSynths = 0
  } else {
    remainingSynths = new Intl.NumberFormat('en-US', {
      notation: 'compact'
    }).format(availableSupply.toNumber())
  }

  let remainingToGenerate
  if (availableToGenerate.gt(infinityThreshold)) {
    remainingToGenerate = '∞'
  } else if (availableDebtSupply.lte(0)) {
    remainingToGenerate = 0
  } else {
    remainingToGenerate = new Intl.NumberFormat('en-US', {
      notation: 'compact'
    }).format(availableDebtSupply.toNumber())
  }

  return (
    <div className={`mt-4 space-y-2 text-sm ${className}`}>
      <p className="flex justify-between capitalize">
        <span className="text-gray-500">{t('asset-tooltip.synth-cap')}</span>
        {synthCap}
      </p>

      <p className="flex justify-between capitalize">
        <span className="text-gray-500">
          {t('asset-tooltip.available-to-generate')}
        </span>
        {remainingToGenerate}
      </p>

      <p className="flex justify-between capitalize">
        <span className="text-gray-500">
          {t('asset-tooltip.available-to-swap')}
        </span>
        {remainingSynths}
      </p>

      <p className="flex justify-between capitalize">
        <span className="text-gray-500">{t('synth-balance-fee')}</span>
        {Big(asset.interestRate).toNumber()}%
      </p>
    </div>
  )
}

export default SyntheticAssetTooltip
