import Big from 'big.js'
import {
  getMinimalRenderableValue,
  isLessThanMinimalRenderableValue
} from 'metronome-utils'
import CountUp from 'react-countup'

import ConditionalBalance from './ConditionalBalance'

const Counter = function ({
  disabled,
  decimals,
  loading,
  minimumValuePrefix,
  end,
  round = v => v,
  prefix,
  ...props
}) {
  const isLessThanMinValue = isLessThanMinimalRenderableValue(end, decimals)
  return (
    <ConditionalBalance balanceLoaded={!loading} disabled={disabled}>
      <CountUp
        decimals={decimals}
        duration={1}
        end={
          isLessThanMinValue
            ? Big(getMinimalRenderableValue(decimals)).toNumber()
            : round(end)
        }
        prefix={isLessThanMinValue ? minimumValuePrefix : prefix}
        preserveValue={true}
        separator=","
        {...props}
      />
    </ConditionalBalance>
  )
}

export default Counter
