import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import ErrorComponent from './ErrorComponent'
import Layout from './Layout'

const Page = function ({
  children,
  hasError = false,
  title,
  showTitle = false
}) {
  const { t } = useTranslation()
  const router = useRouter()
  if (hasError) {
    return <ErrorComponent message={t('error-data')} />
  }
  return (
    <Layout title={`${title} - ${t('app-title')}`}>
      {showTitle && (
        <h2
          className={`mb-8 mt-4 text-2xl font-semibold leading-7 text-white ${
            !router.asPath.includes('/market') ? 'md:hidden' : ''
          }`}
        >
          {title}
        </h2>
      )}
      {children}
    </Layout>
  )
}

export default Page
