import { bigToFiat, uiFiatDecimals } from '../utils'

import Counter from './Counter'

const FiatCounter = ({ prefix = '$', decimals = uiFiatDecimals, ...props }) => (
  <Counter
    decimals={decimals}
    minimumValuePrefix={`<${prefix ? ` ${prefix.trimEnd()}` : ' '}`}
    prefix={prefix}
    round={value => bigToFiat(value, decimals)}
    {...props}
  />
)

export default FiatCounter
