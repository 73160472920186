import Big from 'big.js'
import { WithTooltip } from 'metronome-ui/components/WithTooltip'
import useTranslation from 'next-translate/useTranslation'
import { useContext, useState } from 'react'

import { usePrevious } from '../hooks/usePrevious'
import { useShowUserBalances } from '../hooks/useShowUserBalances'

import Button from './Button'
import CryptoCounter from './CryptoCounter'
import FiatCounter from './FiatCounter'
import IconContainer from './icons/IconContainer'
import { MetronomeContext } from './MetronomeContext'
import WalletContext from './WalletContext'

const Column = function ({ symbol, balance = '0', balanceInUsd = '0', title }) {
  const showUserBalances = useShowUserBalances()
  const { walletBalancesLoaded } = useContext(MetronomeContext)
  const previousCryptoWalletBalance = usePrevious(balance) ?? '0'
  const previousFiatWalletBalance = usePrevious(balanceInUsd) ?? '0'
  return (
    <div className="flex justify-between lg:w-1/2 lg:pl-4 lg:text-right lg:first:text-center">
      <div className="text-gray-secondary lg:hidden lg:text-white">{title}</div>
      <div className="flex w-2/3 flex-row-reverse justify-start whitespace-nowrap lg:w-full lg:flex-col lg:space-y-1">
        <span
          className={`${
            !walletBalancesLoaded && showUserBalances ? 'w-full lg:w-auto' : ''
          }`}
        >
          <CryptoCounter
            disabled={!showUserBalances}
            end={Big(balance).toNumber()}
            loading={!walletBalancesLoaded}
            start={Big(previousCryptoWalletBalance).toNumber()}
          />
          {walletBalancesLoaded && (
            <span className="ml-1 lg:hidden">{symbol}</span>
          )}
        </span>

        <span className="px-1 lg:hidden">{'·'}</span>
        <span
          className={`lg:text-gray-5 ${
            !walletBalancesLoaded && showUserBalances ? 'w-full lg:w-auto' : ''
          }`}
        >
          {
            <FiatCounter
              disabled={!showUserBalances}
              end={Big(balanceInUsd).toNumber()}
              loading={!walletBalancesLoaded}
              start={Big(previousFiatWalletBalance).toNumber()}
            />
          }
        </span>
      </div>
    </div>
  )
}

const AssetList = function ({ SortingArrow, columns, title, children }) {
  const { t } = useTranslation()

  const { walletBalancesLoaded } = useContext(MetronomeContext)
  const { wallet: connectedWallet } = useContext(WalletContext)

  return (
    <>
      <h2 className="mb-4 hidden font-medium capitalize text-white md:block md:text-2xl lg:text-3xl">
        {title}
      </h2>
      <div className="lg:bg-block w-full rounded-md bg-transparent text-white lg:p-6 lg:pt-8">
        <div className="mb-6 hidden justify-between px-4 lg:flex">
          <h3 className="font-regular w-1/3 flex-none text-left text-sm">
            <SortingArrow sortingType="asset">{t('asset')}</SortingArrow>
          </h3>
          {columns.map((column, idx) => (
            <h3
              className={`font-regular flex flex-1 text-sm ${
                idx === 0 ? 'justify-center pl-4' : 'justify-end'
              }`}
              key={column.key}
            >
              {connectedWallet &&
              walletBalancesLoaded &&
              !column.disableSort ? (
                <SortingArrow sortingType={column.key}>
                  {column.title}
                </SortingArrow>
              ) : (
                column.title
              )}
            </h3>
          ))}
        </div>
        <div className="lg:bg-blue-secondary space-y-4 rounded-lg lg:py-2">
          {children}
        </div>
      </div>
    </>
  )
}

AssetList.Item = function AssetListItem({
  InfoComponent,
  asset,
  columns,
  operations
}) {
  const { t } = useTranslation()
  const [showInfo, setShowInfo] = useState(false)

  const {
    debtBalances,
    debtPosition,
    openForbiddenOperationModal,
    openOperationDrawer,
    walletBalancesLoaded
  } = useContext(MetronomeContext)

  const { wallet: connectedWallet } = useContext(WalletContext)

  // @TODO: all this logic would be moved to a reducer when implementing operation stuff in the new state
  const handleOnClick = function (operation) {
    if (
      connectedWallet &&
      (operation === 'generate' || operation === 'withdraw') &&
      Big(debtPosition?.depositInUsd ?? 0).eq(0)
    ) {
      openForbiddenOperationModal('deposit', connectedWallet)
      return
    }
    if (
      connectedWallet &&
      operation === 'repay' &&
      Big(debtBalances[asset.address]?.usdBalance || 0).eq('0')
    ) {
      if (Big(debtPosition?.depositInUsd ?? 0).eq(0)) {
        openForbiddenOperationModal('repay', connectedWallet)
      } else {
        openForbiddenOperationModal('generate', connectedWallet, asset.address)
      }
      return
    }
    openOperationDrawer(operation, asset.address)
  }

  return (
    <div
      className={`
        bg-blue-secondary
        lg:hover:bg-blue-4
        mb-4
        rounded-lg
        p-4
        pb-7
        lg:mx-2
        lg:flex
        lg:h-16
        lg:items-center
        lg:bg-transparent
        lg:p-0
        lg:px-2
        lg:hover:rounded-lg
        ${connectedWallet && walletBalancesLoaded ? 'group' : ''}`}
    >
      <div className="bg-blue-6 mb-0.5 rounded-lg px-3 py-2 lg:mb-0 lg:w-1/3 lg:bg-transparent lg:p-0">
        <WithTooltip
          className="flex items-center justify-between"
          id="tip-action-block"
          key={asset.address}
          overlay={
            <div className="w-50 space-y-2">
              <span className="mb-4 flex items-center text-sm">
                <IconContainer
                  className="mr-2 h-6 w-6"
                  id={`${asset.symbol}_list`}
                  name={asset.symbol.toLowerCase()}
                />
                {asset.symbol}
              </span>
              <InfoComponent asset={asset} />
            </div>
          }
          overlayClassName="!hidden lg:!block"
        >
          <span className="cursor-default">
            <IconContainer
              className="mr-2 inline h-[24px] w-[24px] lg:h-[40px] lg:w-[40px]"
              name={asset.symbol.toLowerCase()}
            />
            {asset.symbol}
          </span>
          <IconContainer
            className="h-3.5 w-3.5 lg:hidden"
            fill={showInfo ? 'white' : '#259DA8'}
            name="exclamationmarkalt"
            onClick={() => setShowInfo(!showInfo)}
          />
        </WithTooltip>

        {showInfo && <InfoComponent asset={asset} className="lg:hidden" />}
      </div>
      <div className="justify-end lg:flex lg:w-2/3">
        <div
          className="
          mt-4
          w-full
          space-y-2
          text-xs
          leading-6
          lg:mt-0
          lg:flex
          lg:space-x-1
          lg:space-y-0
          lg:py-2
          lg:text-base
          lg:group-hover:hidden
          "
        >
          {columns.map(({ title, symbol, balance, balanceInUsd }, idx) => (
            <Column
              balance={balance}
              balanceInUsd={balanceInUsd}
              key={idx}
              symbol={symbol}
              title={title}
            />
          ))}
        </div>
        <div
          className="
          mt-5
          flex
          justify-between
          space-x-2
          whitespace-nowrap
          lg:m-0
          lg:hidden
          lg:justify-normal
          lg:space-x-3
          lg:py-2
          lg:group-hover:flex
          "
        >
          {operations.map(operation => (
            <Button
              className={`w-auto grow py-2 text-sm lg:grow-0 lg:px-3
                ${
                  operation === 'repay' || operation === 'withdraw'
                    ? '!bg-teal-primary-0.2 text-teal-primary lg:bg-teal-primary lg:text-white'
                    : ''
                }
              `}
              data-sentry={`operation-button-${asset.symbol}`}
              key={operation}
              onClick={() => handleOnClick(operation)}
              size="lg"
            >
              {t(`actions.${operation}`)}
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AssetList
