import { bigToCrypto, uiCryptoDecimals } from '../utils'

import Counter from './Counter'

const CryptoCounter = ({ decimals = uiCryptoDecimals, ...props }) => (
  <Counter
    decimals={decimals}
    minimumValuePrefix={`${props.prefix ? `${props.prefix.trimEnd()} ` : ''}< `}
    round={value => bigToCrypto(value)}
    {...props}
  />
)

export default CryptoCounter
