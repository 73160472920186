import Big from 'big.js'
import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'

import { usePrevious } from '../hooks/usePrevious'
import { useShowUserBalances } from '../hooks/useShowUserBalances'

import ActionBlock from './ActionBlock'
import { AppContext } from './AppContext/AppContext'
import FiatCounter from './FiatCounter'
import { MetronomeContext } from './MetronomeContext'

const SyntheticsBlock = function ({ className }) {
  const { t } = useTranslation()
  const { openOperationDrawer, walletBalancesLoaded } =
    useContext(MetronomeContext)
  const showUserBalances = useShowUserBalances()
  const { openForbiddenOperationModal } = useContext(MetronomeContext)

  const {
    state: {
      userPosition: { generated, deposited, issuableLimitInUsd },
      wallet: { connected: connectedWallet }
    }
  } = useContext(AppContext)
  const previousDebtInUsd = usePrevious(generated) ?? '0'
  const previousIssuableLimitInUsd = usePrevious(issuableLimitInUsd) ?? '0'

  const handleGenerate = function () {
    if (connectedWallet && Big(deposited).gt(0)) {
      openOperationDrawer('generate')
    } else {
      openForbiddenOperationModal('deposit', connectedWallet)
    }
  }

  const hasIssuableLimit = Big(issuableLimitInUsd).gt(0)

  return (
    <ActionBlock
      buttonLabel={t('synthetic-block.button')}
      className={className}
      onButtonClick={handleGenerate}
      title={t('synthetic-block.title')}
      tooltipText={
        <p className="text-xs">
          {t(
            `synthetic-block.${
              hasIssuableLimit ? 'tooltip-with-max' : 'tooltip'
            }`
          )}
        </p>
      }
      value={
        <>
          <div className={`flex justify-center text-3xl leading-10`}>
            <FiatCounter
              disabled={!showUserBalances}
              end={Big(generated).toNumber()}
              loading={!walletBalancesLoaded}
              start={Big(previousDebtInUsd).toNumber()}
            />
          </div>
          {hasIssuableLimit && (
            <div className="mt-3 text-base leading-5 text-gray-500">
              {walletBalancesLoaded && t(`synthetic-block.of-max`)}&nbsp;
              <FiatCounter
                disabled={!showUserBalances}
                end={Big(issuableLimitInUsd).toNumber()}
                loading={!walletBalancesLoaded}
                start={Big(previousIssuableLimitInUsd).toNumber()}
              />
            </div>
          )}
        </>
      }
    />
  )
}

export default SyntheticsBlock
