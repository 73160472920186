import { WithTooltip } from 'metronome-ui/components/WithTooltip'
import Link from 'next/link'

import Button from './Button'
import IconContainer from './icons/IconContainer'

const ActionBlock = ({
  className = '',
  title,
  value,
  buttonLabel = '',
  buttonDisabled = false,
  onButtonClick = undefined,
  href = undefined,
  tooltipText = ''
}) => (
  <div
    className={`bg-block flex flex-col justify-between space-y-4 rounded-md p-6 text-center text-white ${className}`}
  >
    <div className="text-gray-secondary flex justify-center">
      <h2 className="text-xl leading-6">{title}</h2>
      {tooltipText && (
        <WithTooltip
          id="tip-action-block"
          overlay={<div className="max-w-[20rem]">{tooltipText}</div>}
        >
          <IconContainer className="-mt-1 ml-2 inline" name="exclamationmark" />
        </WithTooltip>
      )}
    </div>
    {value}
    {onButtonClick && (
      <Button
        className="mr-auto !grow-0 px-10 py-3 text-base"
        data-sentry="action-block-btn"
        disabled={buttonDisabled}
        onClick={onButtonClick}
        size="lg"
      >
        {buttonLabel}
      </Button>
    )}
    {href && (
      <Link href={href}>
        <div className="ml-auto inline-block grow cursor-pointer px-7 py-2 text-sm focus:outline-none">
          {buttonLabel}
        </div>
      </Link>
    )}
  </div>
)

export default ActionBlock
