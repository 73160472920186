import Big from 'big.js'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useContext, useEffect } from 'react'

import useSortCollaterals from '../hooks/useSortCollaterals'
import { sortByNumberProp } from '../utils/sort'

import { AppContext } from './AppContext/AppContext'
import AssetList from './AssetList'
import CollateralAssetInfo from './CollateralAssetInfo'

function CollateralAssetList() {
  const { t } = useTranslation()
  const router = useRouter()

  const {
    state: {
      assets: { collaterals },
      wallet: { balancesLoaded }
    }
  } = useContext(AppContext)

  const { buildSortingUrl, currentSort, order, sortFn, SortingArrow } =
    useSortCollaterals()

  const userCollateralTokens = [...collaterals].sort(
    sortByNumberProp(['deposited'])
  )

  const noCollateralBalance = Big(
    userCollateralTokens.reduce(
      (accumulator, { balance = '0' }) => Big(accumulator).plus(balance),
      '0'
    )
  ).eq(0)

  const noDepositTokenBalance = Big(
    userCollateralTokens.reduce(
      (accumulator, { deposited = '0' }) => Big(accumulator).plus(deposited),
      '0'
    )
  ).eq(0)

  const orderedUserCollateralTokens =
    order === 'desc'
      ? [...userCollateralTokens].sort(sortFn).reverse()
      : [...userCollateralTokens].sort(sortFn)

  const getSortingCriteria = useCallback(
    function () {
      if (!balancesLoaded || router.query['sort-collaterals']) {
        return null
      }

      if (!noDepositTokenBalance) {
        return ['depositToken', 'desc']
      }

      if (!noCollateralBalance) {
        return ['collateral', 'desc']
      }
      return undefined
    },
    [noCollateralBalance, noDepositTokenBalance, router.query, balancesLoaded]
  )

  useEffect(
    function () {
      if (
        !balancesLoaded &&
        (currentSort === 'depositToken' || currentSort === 'collateral')
      ) {
        const { shortName } = router.query
        router.push(`/${shortName}`, undefined, {
          shallow: true
        })
      } else {
        const sortCriteria = getSortingCriteria()
        if (sortCriteria) {
          const href = buildSortingUrl(...sortCriteria)
          router.push(href, undefined, { shallow: true })
        }
      }
    },
    [buildSortingUrl, currentSort, getSortingCriteria, router, balancesLoaded]
  )

  return (
    <AssetList
      SortingArrow={SortingArrow}
      columns={[
        {
          disableSort: noCollateralBalance,
          key: 'collateral',
          title: t('wallet')
        },
        {
          disableSort: noDepositTokenBalance,
          key: 'depositToken',
          title: t('deposited')
        }
      ]}
      title={t('collateral-assets')}
    >
      {orderedUserCollateralTokens.map(asset => (
        <AssetList.Item
          InfoComponent={CollateralAssetInfo}
          asset={asset}
          columns={[
            {
              balance: asset.balance,
              balanceInUsd: asset.balanceInUsd,
              key: 'collateral',
              symbol: asset.symbol,
              title: t('wallet')
            },
            {
              balance: asset.deposited,
              balanceInUsd: asset.depositedInUsd,
              key: 'depositToken',
              symbol: asset.symbol,
              title: t('deposited')
            }
          ]}
          key={asset.address}
          operations={['deposit', 'withdraw']}
        />
      ))}
    </AssetList>
  )
}

export default CollateralAssetList
