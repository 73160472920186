import Big from 'big.js'
import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'

import { usePrevious } from '../hooks/usePrevious'
import { useShowUserBalances } from '../hooks/useShowUserBalances'

import ActionBlock from './ActionBlock'
import { AppContext } from './AppContext/AppContext'
import FiatCounter from './FiatCounter'
import { MetronomeContext } from './MetronomeContext'
import WalletContext from './WalletContext'

const RewardsBlock = function ({ className }) {
  const { t } = useTranslation()
  const { openOperationDrawer, walletBalancesLoaded } =
    useContext(MetronomeContext)
  const {
    state: {
      userPosition: { claimable }
    }
  } = useContext(AppContext)
  const showUserBalances = useShowUserBalances()
  const { wallet: connectedWallet } = useContext(WalletContext)
  const previousClaimable = usePrevious(claimable) || 0

  const handleClaim = function () {
    if (connectedWallet && claimable) {
      openOperationDrawer('claim')
    }
  }

  return (
    <ActionBlock
      buttonDisabled={
        !claimable ||
        Big(claimable).toFixed() === '0' ||
        !connectedWallet ||
        !walletBalancesLoaded
      }
      buttonLabel={t('rewards-block.button')}
      className={className}
      onButtonClick={handleClaim}
      title={t('rewards-block.title')}
      tooltipText={<p className="text-xs">{t('rewards-block.tooltip')}</p>}
      value={
        <div className={`flex justify-center text-3xl leading-10`}>
          <FiatCounter
            disabled={!showUserBalances}
            end={Big(claimable).toNumber()}
            loading={!walletBalancesLoaded}
            start={Big(previousClaimable).toNumber()}
          />
        </div>
      }
    />
  )
}

export default RewardsBlock
