import { useEffect, useState } from 'react'

const TabSelector = function ({
  initialTabIndex = 0,
  onTabChange,
  tabs,
  disabled = false
}) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  useEffect(
    function () {
      if (initialTabIndex) {
        setSelectedTabIndex(initialTabIndex)
      }
      return function () {
        setSelectedTabIndex(0)
      }
    },
    [initialTabIndex]
  )

  const SelectedTab = tabs[selectedTabIndex]
  if (!SelectedTab) {
    return null
  }
  const { props } = SelectedTab

  const handleTabChange = function (index, value) {
    setSelectedTabIndex(index)
    onTabChange?.(value)
  }

  return (
    <>
      {tabs.length > 1 ? (
        <div className="bg-blue-secondary -mb-2 flex rounded-lg p-1 text-white">
          {tabs.map(function (tab, index) {
            const isSelected = tab.name === SelectedTab.name
            return (
              <div
                className={`text-md flex flex-wrap justify-center rounded-lg p-2 text-center leading-5 w-1/${
                  tabs.length
                } ${
                  isSelected
                    ? 'bg-gray-tertiary cursor-default rounded-lg'
                    : `${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`
                }`}
                data-sentry="operation-tab"
                key={tab.name}
                onClick={() =>
                  disabled
                    ? null
                    : handleTabChange(index, tab.name.toLowerCase())
                }
              >
                {tab.label}
              </div>
            )
          })}
        </div>
      ) : null}
      <SelectedTab.component {...props} />
    </>
  )
}

export default TabSelector
