import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import SortingArrow from '../components/SortingArrow'

const SortingArrowWithLink = function ({
  children,
  className = '',
  sortingType,
  currentSort,
  order,
  buildSortingUrl,
  hide
}) {
  const hidden = hide || currentSort !== sortingType
  return (
    <Link
      className="flex cursor-pointer items-center"
      href={buildSortingUrl(sortingType, order === 'asc' ? 'desc' : 'asc')}
      shallow={true}
    >
      {children}
      <SortingArrow className={className} hide={hidden} order={order} />
    </Link>
  )
}

export const useBuildSortingUrl = function (path, sortKey) {
  const router = useRouter()
  const { shortName, ...query } = router.query
  return function (sortingType, order) {
    const queryString = new URLSearchParams({
      ...query,
      [sortKey]: `${encodeURIComponent(sortingType)}-${order}`
    }).toString()
    const _path = `/${shortName}/${path}`
    return `${_path}${queryString && `?${queryString}`}`
  }
}

export const useSort = function ({
  path,
  sortTypes = [],
  defaultSort,
  sortKey = 'sort'
}) {
  const router = useRouter()
  const _buildSortingUrl = useBuildSortingUrl(path, sortKey)
  const queryString = router.query[sortKey] ?? defaultSort
  const defaultLastIndex = defaultSort.lastIndexOf('-')
  const defaultSortingType = queryString.slice(0, defaultLastIndex)
  const lastIndex = queryString.lastIndexOf('-')
  const sortingType = queryString.slice(0, lastIndex)
  const sortingOrder = queryString.slice(lastIndex + 1)
  const currentSort =
    sortTypes.find(({ type }) => type === sortingType)?.type ||
    defaultSortingType
  const order = ['desc', 'asc'].includes(sortingOrder) ? sortingOrder : 'desc'

  useEffect(
    function () {
      window.gtag?.('event', `Apply ${currentSort} ${order} sort`)
    },
    [currentSort, order]
  )

  const sortType = sortTypes.find(({ type }) => type === currentSort)

  return {
    buildSortingUrl: _buildSortingUrl,
    currentSort,
    defaultSort: sortType?.defaultSort,
    order,
    sortFn: sortType?.sortFn,
    SortingArrow: props => (
      <SortingArrowWithLink
        {...props}
        buildSortingUrl={_buildSortingUrl}
        currentSort={currentSort}
        order={order}
        path={path}
      />
    ),
    sortTypes
  }
}
