import useTranslation from 'next-translate/useTranslation'
import { useContext, useState } from 'react'

import { AppContext } from './AppContext/AppContext'
import CurrentHealthFactorModal from './HealthFactorModal'
import IconContainer from './icons/IconContainer'

const CompactHealthFactorBlock = function () {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)

  const {
    state: {
      userPosition: { healthFactor = '-', healthFactorColor }
    }
  } = useContext(AppContext)

  return (
    <div className="bg-block mb-4 rounded-md px-4 py-4 text-white md:order-2 md:m-0 md:mb-8 md:inline-block md:w-auto md:px-4">
      <h2 className="text-gray-secondary text-xl leading-6">
        {t('health-factor')}
        <span
          className="relative top-[-4px] ml-2 inline-block cursor-pointer rounded-full px-2 text-xs font-semibold leading-6 text-white"
          onClick={() => setShowModal(true)}
          style={{ backgroundColor: `${healthFactorColor}` }}
        >
          {healthFactor}
        </span>
        <IconContainer
          className="-mt-1 ml-2 inline cursor-pointer"
          name="exclamationmark"
          onClick={() => setShowModal(true)}
        />
      </h2>
      <CurrentHealthFactorModal
        modalIsOpen={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </div>
  )
}

export default CompactHealthFactorBlock
