import { useWeb3React } from '@web3-react/core'
import { useChainId } from 'metronome-ui/hooks/chains'

import { useWalletChainId } from './useWalletChainId'

export const useShowUserBalances = function () {
  const { isActive: active } = useWeb3React()
  const walletChainId = useWalletChainId()
  const chainId = useChainId()
  // Bypass ganache-core chainId issue by changing 1337 back to mainnet.
  // See: https://github.com/trufflesuite/ganache-core/issues/451
  // Update to 137 if Polygon is required to change back to.
  return active && chainId === walletChainId
}
