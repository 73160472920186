const Loader = ({ className = '' }) => (
  <div
    className={`relative
    isolate
    w-full
    overflow-hidden rounded-lg
    bg-black/30
    shadow-xl
    shadow-black/5
    before:absolute before:inset-0 before:-translate-x-full
    before:animate-[shimmer_1s_infinite]
    before:bg-gradient-to-r
    before:from-transparent before:via-black before:to-transparent
    ${className}`}
  >
    &nbsp;
  </div>
)

export default Loader
