import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'

import { getColor, healthFactorGradient } from '../utils/getUserPosition'

import { AppContext } from './AppContext/AppContext'
import CloseIcon from './icons/Close'
import IconContainer from './icons/IconContainer'
const Modal = dynamic(() => import('./Modal'), {
  ssr: false
})

export const HealthFactorModal = function ({
  modalIsOpen,
  onRequestClose,
  color,
  healthFactor,
  percent,
  end,
  start
}) {
  const { t } = useTranslation()
  return (
    <Modal
      className="min-h-146.252 relative z-50 w-96 text-left text-white md:w-[486px]"
      modalIsOpen={modalIsOpen}
      onRequestClose={onRequestClose}
    >
      <div className="bg-main-opacity rounded-xl p-6">
        <h2 className="text-[32px] font-semibold">
          {t('health-factor-modal.title')}
          <span
            className="relative top-[-6px] ml-2 inline-block rounded-full px-2 text-center text-xs font-semibold leading-7 text-white"
            style={{ backgroundColor: `${color}` }}
          >
            {healthFactor}
          </span>
        </h2>
        <CloseIcon
          className="absolute right-6 top-6 mt-1 h-5 w-5 cursor-pointer"
          onClick={onRequestClose}
        />

        <p className="mb-8 mr-6">{t('health-factor-modal.description')}</p>

        <div className="bg-blue-tertiary relative h-[170px] overflow-hidden rounded-xl p-4 text-center text-xs md:text-sm">
          <div className="relative h-16 w-full">
            {percent && (
              <div
                className={`bg-blue-tertiary absolute bottom-0 z-10 -ml-10 flex w-20 flex-col items-center leading-[15px]`}
                style={{
                  color,
                  left: `${Math.min(end, Math.max(percent, start))}%`
                }}
              >
                <span className="font-semibold">{healthFactor}</span>
                {t('health-factor-modal.your-position')}

                <div
                  className={`mt-2 h-[10px] w-[14px] transition-all`}
                  style={{
                    borderBottom: `10px solid ${color}`,
                    borderLeft: '7px solid transparent',
                    borderRight: '7px solid transparent'
                  }}
                />
              </div>
            )}
          </div>

          <div
            className="relative z-20 h-2 w-full rounded-full"
            style={{ background: `${healthFactorGradient.toCSS()}` }}
          />

          <div className="relative w-full leading-[10px]">
            <div
              className="absolute left-4 top-2"
              style={{ color: healthFactorGradient(0).hex() }}
            >
              <IconContainer
                className="-mt-2 rotate-180"
                fill="white"
                name="pointer"
              />
              10
            </div>

            <div
              className="absolute left-4 top-2"
              style={{ color: healthFactorGradient(0).hex() }}
            >
              <IconContainer
                className="-mt-2 rotate-180"
                fill="white"
                name="pointer"
              />
              10
            </div>

            <div
              className="absolute h-[10px] w-[14px]"
              style={{
                borderLeft: '7px solid transparent',
                borderRight: '7px solid transparent',
                borderTop: `10px solid ${getColor(end)}`,
                left: `${end}%`
              }}
            >
              <div
                className="-ml-10 mt-2 flex w-20 flex-col items-center leading-[15px]"
                style={{ color: getColor(end) }}
              >
                <span className="font-semibold">1</span>
                {t('health-factor-modal.liquidation-value')}
              </div>
            </div>

            <div
              className="absolute right-4 top-2"
              style={{ color: healthFactorGradient(100).hex() }}
            >
              <IconContainer
                className="-mt-2 rotate-180"
                fill="white"
                name="pointer"
              />
              0
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const CurrentHealthFactorModal = function ({ modalIsOpen, onRequestClose }) {
  const start = 9
  const end = 75

  const {
    state: {
      userPosition: { healthFactor = '-', healthFactorColor, percent }
    }
  } = useContext(AppContext)

  return (
    <HealthFactorModal
      color={healthFactorColor}
      end={end}
      getColor={getColor}
      healthFactor={healthFactor}
      modalIsOpen={modalIsOpen}
      onRequestClose={onRequestClose}
      percent={percent}
      start={start}
    />
  )
}

export default CurrentHealthFactorModal
