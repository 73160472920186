// @TODO: market asset list should use this. Then the file could replace the current `useSortSynthetics.js`
import Big from 'big.js'

import { sortByNumberProp } from '../utils/sort'

import { useSort } from './useSort'

const useSortSynthetics = (path = '') =>
  useSort({
    defaultSort: 'asset-asc',
    path,
    sortKey: 'sort-synthetics',
    sortTypes: [
      {
        defaultSort: 'asc',
        sortFn: (a, b) =>
          a.symbol.toLowerCase().localeCompare(b.symbol.toLowerCase()),
        type: 'asset'
      },
      {
        defaultSort: 'asc',
        sortFn: (a, b) => Big(a.interestRate).cmp(b.interestRate),
        type: 'interest-rate'
      },
      {
        defaultSort: 'asc',
        sortFn: sortByNumberProp(['balanceInUsd']),
        type: 'synthetic'
      },
      {
        defaultSort: 'asc',
        sortFn: sortByNumberProp(['balanceInUsd', 'generatedInUsd']),
        type: 'debt'
      }
    ]
  })

export default useSortSynthetics
