import Big from 'big.js'
import {
  getMinimalRenderableValue,
  isLessThanMinimalRenderableValue
} from 'metronome-utils'

import ConditionalBalance from './ConditionalBalance'

const NumberFormat = function ({
  disabled = false,
  decimals = 0,
  loading = false,
  minimumValuePrefix = '',
  prefix = '',
  round = v => v,
  suffix = '',
  value
}) {
  const isLessThanMinValue = isLessThanMinimalRenderableValue(value, decimals)
  const number = new Intl.NumberFormat('en', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals
  })
  return (
    <ConditionalBalance balanceLoaded={!loading} disabled={disabled}>
      {isLessThanMinValue ? minimumValuePrefix : prefix}
      {isLessThanMinValue
        ? Big(getMinimalRenderableValue(decimals)).toNumber()
        : number.format(round(value))}
      {suffix}
    </ConditionalBalance>
  )
}

export default NumberFormat
