import Big from 'big.js'
import useTranslation from 'next-translate/useTranslation'
import { useContext } from 'react'

import { usePrevious } from '../hooks/usePrevious'
import { useShowUserBalances } from '../hooks/useShowUserBalances'

import ActionBlock from './ActionBlock'
import { AppContext } from './AppContext/AppContext'
import FiatCounter from './FiatCounter'
import { MetronomeContext } from './MetronomeContext'

const CollateralBlock = function ({ className }) {
  const { t } = useTranslation()
  const { openOperationDrawer, walletBalancesLoaded } =
    useContext(MetronomeContext)
  const showUserBalances = useShowUserBalances()

  const {
    state: {
      userPosition: { deposited }
    }
  } = useContext(AppContext)

  const previousDepositInUsd = usePrevious(deposited) ?? '0'

  return (
    <ActionBlock
      buttonLabel={t('collateral-block.button')}
      className={className}
      onButtonClick={() => openOperationDrawer('deposit')}
      title={t('collateral-block.title')}
      tooltipText={<p className="text-xs">{t('collateral-block.tooltip')}</p>}
      value={
        <div className={`flex justify-center text-3xl leading-10`}>
          <FiatCounter
            disabled={!showUserBalances}
            end={Big(deposited).toNumber()}
            loading={!walletBalancesLoaded}
            start={Big(previousDepositInUsd).toNumber()}
          />
        </div>
      }
    />
  )
}

export default CollateralBlock
