import { bigToFiat, uiFiatDecimals } from '../utils'

import NumberFormat from './NumberFormat'

const FiatNumber = ({ prefix = '$', decimals = uiFiatDecimals, ...props }) => (
  <NumberFormat
    decimals={decimals}
    minimumValuePrefix={`<${prefix ? ` ${prefix.trimEnd()}` : ' '}`}
    prefix={prefix}
    round={value => bigToFiat(value, decimals)}
    {...props}
  />
)

export default FiatNumber
