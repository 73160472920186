import Big from 'big.js'
import { fromUnit } from 'metronome-utils'

/**
 * Sort objects by one or multiple of it's props that are of type number
 * @param {string[]} numberProps - An array of property names. Those properties' values must be numbers
 * @returns {function(*, *): number}
 */
export const sortByNumberProp = (numberProps = []) =>
  function (a, b) {
    let cmp = 0
    for (let i = 0; i < numberProps.length; i++) {
      if (cmp) {
        continue
      }
      const aBalance = a[numberProps[i]] ?? 0
      const bBalance = b[numberProps[i]] ?? 0
      cmp = Big(aBalance).cmp(bBalance)
    }
    return cmp
  }

/**
 * Sort by the balances in each item's "walletBalances" array
 * @param {string[]} balanceKey - The key of the walletBalances object to sort by
 * @param {string[]} valueKey - The key of the balance to sort by
 * @returns {function(*, *): number}
 */
export const sortByWalletBalances = function (
  balanceKey,
  valueKey = 'usdBalance'
) {
  const getDecimals = token => (valueKey === 'usdBalance' ? 0 : token.decimals)
  return function (a, b) {
    const aBalance = a.walletBalances[balanceKey][valueKey]
    const bBalance = b.walletBalances[balanceKey][valueKey]
    const cmp = Big(fromUnit(aBalance, getDecimals(a))).cmp(
      fromUnit(bBalance, getDecimals(b))
    )
    return cmp
  }
}
